.easy-autocomplete
  .easy-autocomplete-container
    border-radius: 0.1rem
    box-shadow: 0 0 1px gray
    overflow: hidden
    cursor: pointer
    background-color: white
    position: absolute
    top: calc(100% + 0.2rem)
    z-index: 100
    width: inherit !important
    ul
      list-style: none
      margin: 0
      li
        padding: 0.5rem 1rem
        &:hover
          background-color: #eee


// fonts
$ccca_gelbgruen: #a9ce25
$ccca_apfelgruen: #6cbb3d
$ccca_poolblau: #00aff2
$ccca_nachtblau: #013f79

$ccca_blau_midpoint: #009EE3
$ccca_blau_midpoint_darker: darken($ccca_blau_midpoint, 10%)

//$family-primary: 'Open Sans', sans-serif
$family-primary: 'Open Sans', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif

$logo_height_max: 130px
$text: #000
$danger: red
$danger-invert: #fff
$warning: #ff8d00
$warning-invert: #fff

$box-shadow-default: 0 0 15px -4px black
$box-shadow-big: 0 0 15px -2px black
$box-shadow-bigger: 0 0 20px -2px black
$ccca_gradient: linear-gradient(to right, #a9ce25 0%, #6cbb3d 33%, #00aff2 67%, #013f79 100%)

$custom_colors: ("ccca_gelbgruen": ($ccca_gelbgruen, #fff), "ccca_apfelgruen": ($ccca_apfelgruen, #fff), "ccca_poolblau": ($ccca_poolblau, #fff), "ccca_nachtblau": ($ccca_nachtblau, #fff))
$ccca_colors: ("0": ($ccca_gelbgruen, #000), "1": ($ccca_apfelgruen, #000), "2": ($ccca_poolblau, #000), "3": ($ccca_nachtblau, #fff))

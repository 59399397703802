// Import Bulma's core
@import "../../node_modules/bulma/sass/utilities/initial-variables"
@import "../../node_modules/bulma/sass/utilities/functions"

@import "variables"

// Import Bulma and Buefy styles

$section-padding: 1.5rem

$primary: $ccca_poolblau
$link: $ccca_blau_midpoint
$link-hover: $ccca_blau_midpoint_darker

$body-size: 18px
$modal-content-width: 850px

// relative to body-size
$size-1: 2rem
$size-2: 1.7rem
$size-3: 1.4rem
$size-4: 1.25rem
$size-5: 1.1rem
$size-6: 1rem
$size-7: 0.75rem



$title-weight: $weight-normal
@import "../../node_modules/bulma/sass/utilities/derived-variables"

$colors: ("white": ($white, $black), "black": ($black, $white), "light": ($light, $light-invert), "dark": ($dark, $dark-invert), "primary": ($primary, $primary-invert), "link": ($link, $link-invert), "info": ($info, $info-invert), "success": ($success, $success-invert), "warning": ($warning, $warning-invert), "danger": ($danger, $danger-invert),"ccca_gelbgruen": ($ccca_gelbgruen, #000), "ccca_apfelgruen": ($ccca_apfelgruen, #fff), "ccca_poolblau": ($ccca_poolblau, #fff), "ccca_nachtblau": ($ccca_nachtblau, #fff))

@import "../../node_modules/bulma/sass/utilities/derived-variables"
@import "../../node_modules/bulma/bulma"
@import "../../node_modules/buefy/src/scss/buefy"



.image
  &.is-16by7
    padding-top: 43.75%
    img
      +overlay
      height: 100%
      width: 100%


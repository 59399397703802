@import ../node_modules/@fortawesome/fontawesome-free-webfonts/css/fontawesome.css
@import ../node_modules/@fortawesome/fontawesome-free-webfonts/css/fa-solid.css
@import "~@fontsource/open-sans/400.css"
@import "~@fontsource/open-sans/700.css"
@import sass/bulma
@import sass/autocomplete

#logo
  margin-top: 1rem

a
  margin-left: 0.3rem

// vuejs hide while loading
[v-cloak]
  display: none

$background_size_hero: 400px
$background_size_hero_offset: $background_size_hero/3
$background_size_hero_offset_mobile: $background_size_hero*0.8

.hero
  background: url('/img/2018_pixel_100_2_hell_vektor_trans.png') repeat-y
  background-position: calc(100% + #{$background_size_hero_offset_mobile}) 100%
  background-size: $background_size_hero
  +tablet
    background-position: calc(100% + #{$background_size_hero_offset}) 100%

  .title
    font-size: 3rem

  .subtitle
    font-size: 2rem

.field-container
  margin-bottom: 1.4rem

b.is-danger
  color: $danger

.form
  .columns
    padding-bottom: 1rem
    margin-bottom: 1rem

    .column + .column
      border-left: 1px solid #ccc

.navbar-item.is-warning
  color: red !important


.field.left-padd
  padding-left: 3rem

.info_text
  margin-top: 1rem

.day

  margin-top: 3rem

  .event
    &:not(:first-child)
      margin-top: 1rem

.order-1
  order: 1

.order-2
  order: 2


.preview-area
  display: flex
  flex-direction: column

  .preview-row-info
    width: 100%
    font-size: 0.7rem
    overflow: auto
    white-space: nowrap
    font-family: monospace

    display: flex

    .row-content
      .row-text
        white-space: nowrap
        background: #f3f2f2

    .visible-newline
      background-color: rgba(255, 128, 0, 0.3)